<template>
    <div class="modal add_step secondary" v-if="modelValue" :class="{active : modelValue}">
        <div class="modal_container">
            <div class="modal_body">
                <button class="close_btn" @click="closeModal()"><i class="fas fa-times"></i></button>
                <div class="upload_image m-0">
                    <image-library v-model="form.thumb" image-type="dashboard-thumb" :is-inside="true" />
                </div>
                <div class="step_content">
                    <Form @submit="handleSubmitForm" v-slot="{ errors }" ref="new-workout-form">
                        <div class="setting_wpr">
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Workout Name</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.name }">
                                        <Field autocomplete="off" type="text" name="name" v-model="form.name" placeholder="ex: New Workout" rules="required" />
                                    </div>
                                    <ErrorMessage name="name" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Workout Categories <a class="option" @click="createCategory = !createCategory">Create Category</a></label>
                                        <div class="field_wpr">
                                        <multiselect
                                            v-model="form.categories"
                                            :options="workoutCategories"
                                            :searchable="true"
                                            valueProp="id"
                                            label="name"
                                            placeholder="Select Category"
                                            mode="tags"
                                        ></multiselect>
                                    </div>
                                </div>
                            </div>
                            <div v-if="createCategory" class="form_grp mb-2">
                                <div class="group_item">
                                    <label class="input_label">Category Name</label>
                                    <Form @submit="handleCreateCategory" v-slot="{ errors }" ref="category-form">
                                        <div class="field_wpr has_suffix" :class="{ 'has-error': errors.name }">
                                            <Field autocomplete="off" type="text" name="name" v-model="categoryForm.name" placeholder="Enter Category Name" rules="required" />
                                            <span class="suffix" @click="handleCreateCategory()"><i class="fa fa-spin fa-spinner" v-if="categorySaveLoader"></i> {{ categorySaveLoader ? 'Creating' : 'Create' }}</span>
                                        </div>
                                        <ErrorMessage name="name" class="text-danger" />
                                    </Form>
                                </div>
                            </div>
                            <div class="form_grp mb-2">
                                <div class="group_item">
                                    <label class="input_label">Description</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.description }">
                                        <Field name="description" v-model="form.description" label="description" autocomplete="off" rules="required" >
                                            <textarea name="description" autocomplete="off" cols="30" rows="10" placeholder="Description goes here.." v-model="form.description"></textarea>
                                        </Field>
                                    </div>
                                    <ErrorMessage name="description" class="text-danger" />
                                </div>
                            </div>
                            <label for="form_private" class="switch_option capsule_btn border-0">
                                <h5 class="large">Make Private?</h5>
                                <button type="button" class="video_btn" @click.prevent="handleToggleVoice()">
                                   <i class="fas fa-play" v-if="!isAudioPlay"></i>
                                   <i class="fas fa-pause" v-else></i>
                               </button>
                                <input type="checkbox" id="form_private" v-model="form.is_private" :true-value="1" :false-value="0" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                    </Form>
                    <div class="action_wpr">
                        <button :disabled="loader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                        <button :disabled="loader" type="button" class="btn save_btn" @click="handleSubmitForm"><i class="fa fa-spinner fa-spin" v-if="loader"></i> {{ loader ? 'Creating' : 'Create' }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapActions, mapState } from 'vuex'

    const ImageLibrary = defineAsyncComponent(() => import('@/components/image-library/ImageLibrary'))
    const publicPrivate = new Audio(require('@/assets/audio/workout/public-vs-private-wokout.mp3'))

    export default {
        name: 'New Workout',

        data () {
            return {
                form: {
                    name: '',
                    thumb: '',
                    description: '',
                    is_private: 0,
                    categories: [],
                },
                loader: false,
                isAudioPlay: false,
                createCategory: false,
                categoryForm: {
                    name: '',
                    is_workout_plan: 0,
                }
            }
        },

        props: {
            modelValue: Boolean,
            refreshAsset: {
                type: Function,
                default: () => {}
            }
        },

        emit: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
            ImageLibrary,
        },

        watch: {
            modelValue (value) {
                const vm = this;

                if (value) {
                    vm.getWorkoutCategories({ is_workout_plan: 0 });
                    vm.resetForm();
                }
            }
        },

        computed: mapState({
            categorySaveLoader: state => state.workoutModule.categorySaveLoader,
            workoutCategories: state => state.workoutModule.workoutCategories,
        }),

        methods: {
            ...mapActions({
                createWorkout: 'workoutModule/createWorkout',
                getWorkoutCategories: 'workoutModule/getWorkoutCategories',
                createWorkoutCategory: 'workoutModule/createWorkoutCategory',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleSubmitForm () {
                const vm = this;

                const form = vm.$refs['new-workout-form'];

                form.validate().then((result) => {
                    if (result.valid) {
                        vm.handleCreateWorkout(form);
                    }
                });
            },

            handleCreateWorkout (form) {
                const vm = this;

                vm.form.setFieldError = form.setFieldError;
                vm.loader             = true;

                vm.createWorkout(vm.form).then((result) => {
                    vm.loader = false;

                    if (result) {
                        vm.refreshAsset();
                        vm.closeModal();
                    }
                });
            },

            resetForm () {
                const vm = this;

                vm.form  = { name: '', thumb: '', description: '', is_private: 0 };

                vm.categoryForm = {
                    name: '',
                    is_workout_plan: 0,
                };

                vm.createCategory = false;
            },

            handleToggleVoice () {
                const vm = this;

                if (vm.isAudioPlay) {
                    publicPrivate.pause();
                    publicPrivate.currentTime = 0;
                    vm.isAudioPlay = false;
                } else {
                    publicPrivate.play();
                    vm.isAudioPlay = true;
                }

                publicPrivate.addEventListener('ended', () => {
                    this.currentTime = 0;
                    vm.isAudioPlay   = false;
                }, false);
            },

            handleCreateCategory () {
                const vm = this;

                const categoryForm = vm.$refs['category-form'];

                categoryForm.validate().then((result) => {
                    if (result.valid) {
                        vm.categoryForm.setFieldError = categoryForm.setFieldError;

                        vm.createWorkoutCategory(vm.categoryForm).then((result) => {
                            if (result) {
                                vm.createCategory    = false;
                                vm.categoryForm.name = '';
                                vm.getWorkoutCategories({ is_workout_plan: 0 });
                            }
                        });
                    }
                });
            },
        }
    }
</script>

<style scoped>
    .step_content {
        padding: 20px 30px 10px 30px;
        flex: 1;
    }

    .step_content .action_wpr {
        width: 100%;
        padding-top: 5px;
    }

    .close_btn {
        position: absolute;
        right: 15px;
        left: auto;
        top: 15px;
        border: 0;
        background: rgba(50,55,59,.6);
        font-size: 12px;
        color: #fff;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        min-width: 20px;
    }

    .switch_option h5 {
        text-align: left;
        margin: 0;
    }

    .upload_image.image_create {
        margin-bottom: 0;
    }

    .upload_image.image_create .upload_wpr {
        height: 100%;
    }

    :deep(.upload_image .upload_wpr) {
        height: 100% !important;
    }

    .video_btn button.video_btn {
        width: 25px;
        height: 25px;
    }

    :deep(.form_grp .multiselect .multiselect-dropdown) {
        max-height: 200px;
    }
</style>
